import "./employeesList.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import newRequest from "../../utils/newRequest";
import { CircularProgress, Modal } from "@mui/material";
import DoneIcon from "@mui/icons-material/CheckCircleOutlined";
import { useMediaQuery } from "@mui/material";
import Arrow from "@mui/icons-material/KeyboardArrowDownOutlined";
import { DatePicker, message } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils/formatDate";
import MultiSelect from "../ui/MultiSelect";
import { createRoleApi, getAllPermissionsApi } from "../../APIs/roles";

const EmployeesList = ({ employees }) => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(min-width:768px) and (max-width:1023px)");
  const isDisktop = useMediaQuery("(min-width:1023px)");
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [role, setRole] = useState(null);
  const [name, setName] = useState(null);
  const [tyeps, setTyeps] = useState([]);
  const [salary, setSalary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(null);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [dates, setDates] = useState([]);
  const { RangePicker } = DatePicker;
  const [filterdEmployees, setFilterdEmployees] = useState([]);
  const [editEmployee, setEditEmployee] = useState(null);
  const [openEditEmployee, setOpenEditEmployee] = useState(false);
  const [showAddRole, setshowAddRole] = useState(false);
  const [employee, setEmployee] = useState({
    name: editEmployee?.name,
    email: editEmployee?.email,
    typeId: editEmployee?.typeId,
    salary: editEmployee?.salary,
  });
  const Lang = localStorage.getItem("i18nextLng");
  const [t] = useTranslation();

  useEffect(() => {
    const getTypes = async () => {
      try {
        const res = await newRequest.get("/role");
        setTyeps(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getTypes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      setLoading(true);
      const res = await newRequest.post("/stuff", {
        email: email,
        name: name,
        password: password,
        typeId: role,
        salary: salary,
      });
      console.log(res);
      setLoading(false);
      setRole(null);
      setDone(true);
    } catch (error) {
      console.log(error);
      setError(error.response.data.errors[0].message);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await newRequest.delete(`/stuff/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const requestBody = { ...employee };

      if (password) {
        requestBody.password = password;
      }

      const res = await newRequest.put(
        `/stuff/${editEmployee.id}`,
        requestBody
      );
      console.log(res);
      setEditEmployee(null);
      setEmployee({});
      setDone(true);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  const handleFilter = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await newRequest.get(`/stuff?fromDate=${from}&toDate=${to}`);
      setFilterdEmployees(res?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
      setDates(null);
      setLoading(false);
    }
  };

  const handleDateChange = (values) => {
    if (values) {
      const startDate = values[0].format("YYYY-MM-DD");
      const endDate = values[1].format("YYYY-MM-DD");
      setDates([startDate, endDate]);
      setFrom(startDate);
      setTo(endDate);
    } else {
      setDates(null);
    }
  };

  const handleOpenChange = (open) => {
    if (open && dates?.length === 2) {
      setDates([]);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: t("action"),
      width: isMobile ? 120 : isTablet ? 160 : 200,
      align: "center",
      renderCell: (params) => {
        const isAdmin = params.row && !params.row.typeId;
        return (
          <div className='cellAction'>
            <div
              className='edit-button'
              onClick={() => {
                setEditEmployee(params.row);
                setOpenEditEmployee(true);
              }}
            >
              {t("edit")}
            </div>
            {!isAdmin && (
              <div
                className='delete-button'
                onClick={() => handleDelete(params.row.id)}
              >
                {t("delete")}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const employeColumns = [
    {
      field: "email",
      headerName: t("email"),
      width: isMobile ? 120 : isTablet ? 160 : 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: t("name"),
      width: isMobile ? 120 : isTablet ? 160 : 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "typeData",
      headerName: t("job"),
      width: isMobile ? 120 : isTablet ? 160 : 200,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params?.row?.typeData[0]?.key,
    },
    { field: "salary", headerName: t("salary"), width: 160, align: "center" },

    {
      field: "connectionTime",
      headerName: t("duration"),
      width: isMobile ? 120 : isTablet ? 160 : 200,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        const { connectionTime } = params.row;
        const { hours, minutes } = formatDate(connectionTime);
        return `${hours} ${t("hour")} و ${minutes} ${t("minute")}`;
      },
    },
  ];

  return (
    <>
      <div className='datatable'>
        <div
          className='datatable-header'
          style={{ justifyContent: "space-between" }}
        >
          <div className='flex items-center gap-3'>
            <Link onClick={() => setOpen(true)} className='add-link'>
              {t("addEmployee")}
            </Link>
            <Link onClick={() => setshowAddRole(true)} className='add-link'>
              {t("addRole")}
            </Link>
          </div>

          <div className='date-container'>
            <div className='date-picker'>
              <RangePicker
                onChange={handleDateChange}
                onOpenChange={handleOpenChange}
                value={
                  dates?.length === 2
                    ? [moment(dates[0]), moment(dates[1])]
                    : null
                }
              />
            </div>

            <div className='action-control'>
              <button
                onClick={() => {
                  setFilterdEmployees([]);
                  setDates(null);
                }}
              >
                {t("cancel")}
              </button>
              <button onClick={handleFilter}>{t("search")}</button>
            </div>
          </div>
        </div>

        <DataGrid
          className='datagrid'
          rows={filterdEmployees?.length > 0 ? filterdEmployees : employees}
          params={filterdEmployees?.length > 0 ? filterdEmployees : employees}
          columns={
            isDisktop
              ? employeColumns?.concat(actionColumn)
              : employeColumns?.concat(actionColumn).reverse()
          }
          pageSize={10}
          rowsPerPageOptions={[10]}
          sx={{
            height: "90%",
            backgroundColor: "white",
            direction: isMobile || isTablet ? "ltr" : "rtl",
          }}
          disableVirtualization
        />
      </div>

      {/* Modal For Add Employee */}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='moadl-container'>
          {loading && (
            <div className='progress-container'>
              <CircularProgress width={40} className='circular-progress' />
            </div>
          )}

          {done && (
            <div className='message-container'>
              <div className='message'>
                <div className='content'>
                  <DoneIcon className='icon ' />
                  <p>{t("success")}</p>
                </div>
                <button
                  onClick={() => {
                    setDone(false);
                    setOpen(false);
                    window.location.reload();
                  }}
                >
                  {t("ok")}
                </button>
              </div>
            </div>
          )}

          <div className='form-container'>
            <form action='' onSubmit={handleSubmit}>
              <div className='form-control'>
                <input
                  type='email'
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t("email")}
                />
                {error && <span>{t("emailError")}</span>}
              </div>

              <div className='form-control'>
                <input
                  type='text'
                  required
                  onChange={(e) => setName(e.target.value)}
                  placeholder={t("name")}
                />
              </div>

              <div className='form-control'>
                <input
                  type='text'
                  required
                  pattern='^[1-9]\d*$'
                  onChange={(e) => setSalary(e.target.value)}
                  placeholder={t("salary")}
                />
              </div>

              <div className='form-control' style={{ position: "relative" }}>
                <Arrow
                  style={{
                    position: "absolute",
                    right: Lang === "en" && "10px",
                    left: Lang === "ar" && "10px",
                    fontSize: "32px",
                    color: "#515151",
                  }}
                />

                <select
                  name=''
                  id=''
                  required
                  onChange={(e) => setRole(e.target.value)}
                  value={role || ""}
                  style={{ color: "#515151" }}
                >
                  <option value='' disabled>
                    {t("job")}
                  </option>
                  {tyeps?.map((item) => (
                    <option value={item?.id} key={item?.id}>
                      {item?.key}
                    </option>
                  ))}
                </select>
              </div>

              <div className='form-control'>
                <input
                  type='text'
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={t("password")}
                />
              </div>

              <div className='form-action'>
                <button type='submit' className='action-btn'>
                  {t("add")}
                </button>
                <button
                  type='submit'
                  className='action-btn-cancel'
                  onClick={() => {
                    setOpen(false);
                    setError(null);
                    setRole(null);
                  }}
                >
                  {t("cancel")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/* Modal For Edit Employee */}
      <Modal
        open={openEditEmployee}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='moadl-container'>
          {loading && (
            <div className='progress-container'>
              <CircularProgress width={40} className='circular-progress' />
            </div>
          )}

          {done && (
            <div className='message-container'>
              <div className='message'>
                <div className='content'>
                  <DoneIcon className='icon ' />
                  {error ? <p>{t("serverError")}</p> : <p>{t("editDone")}</p>}
                </div>
                <button
                  onClick={() => {
                    setDone(false);
                    setOpenEditEmployee(false);
                    window.location.reload();
                  }}
                >
                  {t("ok")}
                </button>
              </div>
            </div>
          )}

          <div className='form-container'>
            <form action='' onSubmit={handleEdit}>
              <div className='form-control'>
                <input
                  type='email'
                  onChange={(e) =>
                    setEmployee((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                  placeholder={editEmployee?.email}
                />
              </div>

              <div className='form-control'>
                <input
                  type='text'
                  maxLength={30}
                  onChange={(e) =>
                    setEmployee((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                  placeholder={editEmployee?.name}
                />
              </div>

              {editEmployee?.name !== "admin" && (
                <>
                  <div className='form-control'>
                    <input
                      type='text'
                      maxLength={6}
                      pattern='^[1-9]\d*$'
                      onChange={(e) =>
                        setEmployee((prev) => ({
                          ...prev,
                          salary: e.target.value,
                        }))
                      }
                      placeholder={editEmployee?.salary}
                    />
                  </div>

                  <div
                    className='form-control'
                    style={{ position: "relative" }}
                  >
                    <Arrow
                      style={{
                        position: "absolute",
                        fontSize: "32px",
                        color: "#515151",
                        right: Lang === "en" && "10px",
                        left: Lang === "ar" && "10px",
                      }}
                    />
                    <select
                      name=''
                      id=''
                      onChange={(e) =>
                        setEmployee((prev) => ({
                          ...prev,
                          typeId: e.target.value,
                        }))
                      }
                      style={{ color: "#515151" }}
                    >
                      <option value={editEmployee?.typeId} disabled selected>
                        {editEmployee?.typeId?.key}
                      </option>
                      {tyeps?.map((item) => (
                        <option value={item?.id} key={item?.id}>
                          {item?.key}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}

              <div className='form-control'>
                <input
                  type='text'
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={t("password")}
                />
              </div>

              <div className='form-action'>
                <button type='submit' className='action-btn'>
                  {t("edit")}
                </button>
                <button
                  className='action-btn-cancel'
                  onClick={() => {
                    setOpenEditEmployee(false);
                    setEditEmployee(null);
                  }}
                >
                  {t("cancel")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <AddRoleModal showAddRole={showAddRole} setshowAddRole={setshowAddRole} />
    </>
  );
};

export default EmployeesList;

const AddRoleModal = ({ showAddRole, setshowAddRole }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [roleName, setroleName] = useState("");
  const [selectedPermissions, setselectedPermissions] = useState([]);
  const [allPermissions, setallPermissions] = useState([]);

  const [loading, setloading] = useState(false);

  const resetAllFields = () => {
    setroleName("");
    setselectedPermissions([]);
  };

  const handleAddRole = () => {
    setloading(true);
    createRoleApi({
      key: roleName,
      permission: selectedPermissions,
    })
      .then(() => {
        message.success(
          language === "ar" ? "تم الإضافة بنجاح" : "created successfully"
        );
        setshowAddRole(false);
        resetAllFields();
        setloading(false);
      })
      .catch((err) => {
        message.error(err.response.data.errors[0]["message"]);
        setloading(false);
      });
  };

  useEffect(() => {
    getAllPermissionsApi().then((res) => {
      const permissions = res.data.data;

      const permissionsOptions = [
        ...new Set(Object.values(permissions).flat()),
      ].map((permission) => {
        return {
          value: permission,
          label: t(`permissions-list.${permission}`),
        };
      });

      setallPermissions(permissionsOptions);
    });
  }, []);

  return (
    <Modal
      open={showAddRole}
      onClose={() => setshowAddRole(false)}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className='moadl-container'>
        <div className='form-container'>
          <div className='flex flex-col gap-6 py-6'>
            <div className=" text-start text-[#19191c] text-2xl font-bold font-['Cairo'] capitalize">
              {t("addRole")}{" "}
            </div>
            <div className='flex flex-col items-start gap-1'>
              <div className="text-start text-[#3e3e3e] text-xs font-bold font-['Cairo'] leading-[18px]">
                {t("role_name")}
              </div>
              <input
                value={roleName}
                onChange={(e) => setroleName(e.target.value)}
                placeholder={t("role_name")}
                className='max-w-[525px] w-full !outline-none h-12 px-4 py-3 bg-white rounded-[58px] border border-[#e4e4e4] justify-start items-center gap-2.5 inline-flex text-start placeholder:text-[text-[#6f6f6f]'
              ></input>
            </div>
            <div className='flex flex-col items-start gap-1 mb-36'>
              <div className="text-start text-[#3e3e3e] text-xs font-bold font-['Cairo'] leading-[18px]">
                {t("permissions")}
              </div>
              <MultiSelect
                options={allPermissions}
                selectedOptions={selectedPermissions}
                setSelectedOptions={setselectedPermissions}
                placeholder={t("select_permissions_placeholder")}
              />
            </div>

            <div className='h-20 w-full  pt-2 pb-6 bg-white justify-end items-center gap-2 inline-flex'>
              <div className=' self-stretch justify-start items-center gap-3 flex'>
                <button
                  onClick={() => {
                    setshowAddRole(false);
                  }}
                  className='justify-start items-start flex'
                >
                  <div className='px-12 py-3 rounded-[58px] border border-[#ff5c00] justify-center items-center gap-2.5 flex'>
                    <div className="text-[#ff5c00] text-base font-bold font-['Cairo'] leading-normal">
                      {t("cancel")}
                    </div>
                  </div>
                </button>
                <button
                  onClick={() => handleAddRole()}
                  className='justify-start items-start flex disabled:opacity-50'
                  disabled={
                    loading ||
                    !roleName ||
                    !selectedPermissions ||
                    selectedPermissions.length === 0
                  }
                >
                  <div className='px-12 py-3 bg-[#ff5c00] rounded-[58px]  justify-center items-center gap-2.5 flex'>
                    <div className="text-white text-base font-bold font-['Cairo'] leading-normal">
                      {t("add")}
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
