import Axios from "axios";

//create role api
export const createRoleApi = (data) => {
  let result = Axios.post("/role", data);
  return result;
};

// get all permissions
export const getAllPermissionsApi = () => {
  let result = Axios.get("/role/permission");
  return result;
};
