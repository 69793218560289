import React, { useState, useRef, useEffect } from "react";
import { cn } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

const MultiSelectDropdown = ({
  options,
  selectedOptions,
  setSelectedOptions,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tempSelectedOptions, setTempSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);
  const {
    i18n: { language },
  } = useTranslation();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    // Sync temp selections with the current selections when opening
    if (!isOpen) {
      setTempSelectedOptions(selectedOptions);
    }
  };

  const handleSelect = (id) => {
    setTempSelectedOptions((prev) =>
      prev.includes(id)
        ? prev.filter((optionId) => optionId !== id)
        : [...prev, id]
    );
  };

  const confirmSelection = () => {
    setSelectedOptions(tempSelectedOptions);
    setIsOpen(false);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className='relative max-w-full w-[528px]' ref={dropdownRef}>
      <div
        className={cn(
          "flex justify-between select-none items-center transition-all bg-white py-3 px-4 border border-[#E4E4E4]  cursor-pointer rounded-t-2xl",
          { "rounded-b-2xl": !isOpen }
        )}
        onClick={toggleDropdown}
      >
        {selectedOptions.length > 0 ? (
          <div className='flex flex-wrap gap-2'>
            {selectedOptions.map((optionId) => (
              <div
                key={optionId}
                className='bg-orange-100 text-[#FF611D] text-sm px-2 py-1 rounded-full'
              >
                {options.find((option) => option.value === optionId)?.label}
              </div>
            ))}
          </div>
        ) : (
          <span className='text-[#19191C] text-sm'>
            {placeholder || "Select an option"}
          </span>
        )}
        <svg
          className={`w-4 h-4 transform ${isOpen ? "rotate-180" : ""}`}
          fill='none'
          stroke='currentColor'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M19 9l-7 7-7-7'
          ></path>
        </svg>
      </div>

      <div
        className={cn(
          "absolute transition-all opacity-0 left-0 right-0 overflow-hidden  bg-white border border-t-0 border-[#E4E4E4] rounded-b-2xl  z-10 h-0",
          { "h-62 opacity-100": isOpen },
          { "border-0": !isOpen }
        )}
      >
        <div className='max-h-44 overflow-y-auto'>
          {/* sort options by selected ones, make selected options first */}

          {options
            .sort((a, b) => {
              if (selectedOptions.includes(a.value)) return -1;
              if (selectedOptions.includes(b.value)) return 1;
              return 0;
            })
            .map((option) => (
              <div
                key={option.value}
                className={cn(
                  "flex items-center text-[#19191C] text-base justify-between py-3 px-4 border-b border-[#E4E4E4] cursor-pointer ",
                  {
                    "bg-orange-100 text-[#FF611D]":
                      tempSelectedOptions.includes(option.value),
                  }
                )}
                onClick={() => handleSelect(option.value)}
              >
                <span className='ml-2 text-sm'>{option.label}</span>

                <div class='inline-flex items-center'>
                  <label class='flex items-center cursor-pointer relative'>
                    <input
                      type='checkbox'
                      checked={tempSelectedOptions.includes(option.value)}
                      class='peer h-5 w-5 cursor-pointer transition-all appearance-none rounded  border border-slate-300 checked:bg-[#FF5C00] checked:border-[#FF5C00]'
                      id='check'
                    />
                    <span class='absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        class='h-3.5 w-3.5'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                        stroke='currentColor'
                        stroke-width='1'
                      >
                        <path
                          fill-rule='evenodd'
                          d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                          clip-rule='evenodd'
                        ></path>
                      </svg>
                    </span>
                  </label>
                </div>
              </div>
            ))}
        </div>
        <div className='w-full px-4 flex items-center mt-4 mb-3'>
          <button
            className='w-full bg-[#FF5C00] rounded-[58px] text-white py-3 font-bold text-base  '
            onClick={confirmSelection}
          >
            {language === "ar" ? "تأكيد" : "confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MultiSelectDropdown;
